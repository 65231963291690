// .ForumDetails {
// }
.forum_details_header {
  border-bottom: 1px solid #f2f2f2;
  padding: 26px;
  align-self: center;
  padding-bottom: 10px;
}
.forum_details_header p {
  margin-bottom: 0px;
}
.forum_details_body {
  padding: 10px 24px;
}
.forum_details_image {
  width: 20px;
  margin-right: 10px;
}
.forum_details_body p {
  color: #000000;
  font-size: 14px;
  margin-bottom: 0px;
}
.forum_details_body span {
  color: #888;
  font-size: 12px;
}
.forum_details {
  margin: 20px 0;
}
.forumdetails-section {
  padding-bottom: 24px;
}
