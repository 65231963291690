.ForumStatistics {
}
.profile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #f4f5f8;
  font-size: 25px;
  color: #ffcf33;
  text-align: center;
  line-height: 50px;
  margin: 20px 0;
}
.page-meta > a {
  display: block;
}
.your-page {
  padding: 0 20px 0px;
}
.ForumStatistics .forum_part {
  box-shadow: 0px 10px 30px #d1d5df80;
  padding: 18px;
  border-radius: 20px;
  margin: 38px 0px;
  cursor: pointer;
}
.ForumStatistics .forum_part .noti_cir {
  background: #ffcc3a;
  padding: 4px 4px;
  color: white;
  font-size: 11px;
  margin-left: 4px;
  border-radius: 50%;
}
.statistics-content p {
  margin-bottom: 5px;
  color: #888;
}
.forum-header {
  color: #000;
  font-size: 15px;
  font-weight: 500;
}
.activity-content {
  border-left: 3px solid #ffcc3a;
  padding: 0 10px;
}
.forum_activity_details {
  color: #000;
  font-size: 12px;
}
.activity-content span,
.featured_topics span {
  font-size: 11px;
  color: #888;
}
.activity-content p {
  color: #ffcc3a;
  margin-bottom: 0px;
  font-size: 12px;
}
.activity_section,
.forum_featured_section {
  margin: 10px 0;
}
.forum_statistics_image {
  width: 20px;
}
.featured_topics {
  padding-left: 10px;
}
.featured_topics p {
  color: #000;
  font-size: 13px;
  margin-bottom: 0px;
}
