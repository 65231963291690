.logo-img {
  height: 64px;
  margin-top: 6px;
}
.header-icon {
  cursor: pointer;
}
.sidenav {
  height: 100%;
  width: 300px;
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  box-shadow: 0 -4px 4px #00000054;
}
.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 14px;
  color: #000;
  display: block;
  transition: 0.3s;
}
.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 32px;
  border-radius: 50%;
  color: #000;
}
.menuimg-responsive {
  width: 18px;
  margin-right: 6px;
}
a > em {
  border-radius: 100%;
  color: #fff;
  font-size: 10px;
  height: 17px;
  line-height: 17px;
  position: absolute;
  right: 6px;
  text-align: center;
  top: 10px;
  width: 17px;
  font-style: normal;
}

.fa-user {
  font-size: inherit;
  text-rendering: auto;
}

.dropdown > a {
  color: #999;
  font-size: 18px;
  position: relative;
  display: inline-block;
  width: 50px;
  text-align: center;
  transition: all 0.2s linear 0s;
  vertical-align: middle;
}

.frd-reg-img {
  width: 3rem;
}
.mesg-meta-fr-dropdown {
  color: #515365;
  height:30px;
}
.mesg-meta-fr-dropdown:hover {
  color: #ffcf34 !important;
}
.mesg-meta-fr-dropdown-big {
  font-size: 0.8rem !important;
  overflow: hidden;
  font-weight: 500;
}
.mesg-meta-fr-dropdown-small {
  font-size: 0.7rem !important;
  overflow: hidden;
  white-space: pre-line;
}
// .dropdown .dropdown-menu {
// 	-webkit-transition: max-height 0.6s ease, opacity 0.3s 0.1s, visibility 0s 1s;
// 	-moz-transition: max-height 0.6s ease, opacity 0.3s 0.1s, visibility 0s 1s;
// 	-ms-transition: max-height 0.6s ease, opacity 0.3s 0.1s, visibility 0s 1s;
// 	-o-transition: max-height 0.6s ease, opacity 0.3s 0.1s, visibility 0s 1s;
// 	transition: max-height 0.6s ease, opacity 0.3s 0.1s, visibility 0s 1s;

// 	max-height: 0;

// 	display: block;
// 	overflow: hidden;
// 	opacity: 0;
// 	visibility: hidden;
// }

.dropdown.open .dropdown-menu:hover,
.dropdown.show .dropdown-menu:hover {
  overflow-y: auto !important;
}

.dropdown.open .dropdown-menu,
.dropdown.show .dropdown-menu {
  transition: all 0.5s ease;
  -webkit-transition: max-height 0.6s ease, opacity 0.3s, visibility 0s;
  -moz-transition: max-height 0.6s ease, opacity 0.3s, visibility 0s;
  -ms-transition: max-height 0.6s ease, opacity 0.3s, visibility 0s;
  -o-transition: max-height 0.6s ease, opacity 0.3s, visibility 0s;
  transition: max-height 0.6s ease, opacity 0.3s, visibility 0s;

  max-height: 40vh;
  // max-height: 100px;
  overflow-y: hidden !important;
  opacity: 1;
  visibility: visible;
}
.dropdown.open .dropdown-menu,
.dropdown.show .dropdown-menu::-webkit-scrollbar {
  border-radius: 2rem;
  width: 0.5rem;
}

/* Track */
.dropdown.open .dropdown-menu,
.dropdown.show .dropdown-menu::-webkit-scrollbar-track {
  border-radius: 2rem;
  background: #f1f1f155;
}

/* Handle */
.dropdown.open .dropdown-menu,
.dropdown.show .dropdown-menu::-webkit-scrollbar-thumb {
  border-radius: 2rem;
  background: #8888;
}

/* Handle on hover */
.dropdown.open .dropdown-menu,
.dropdown.show .dropdown-menu::-webkit-scrollbar-thumb:hover {
  background: #5558;
}
.setting-area li i {
  background-color: white;
  color: #999;
  transition: all 0.1s ease, transform 0.2s ease;
}
.setting-area li i:hover {
  color: #a5dad0;
  transform: translateY(-2px);
}
