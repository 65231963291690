.ForumSlider {
  margin-bottom: 20px;
}
.main-background {
  background: #edf3fa;
  padding: 20px 40px;
  margin-top: 80px;
  padding-bottom: 0px;
}
.forum-image {
  width: 80%;
}
.forumslider-header {
  color: #ffcc3a;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 2rem;
}
.supprt_searchbtn {
  background: #ffcc3a;
  color: #fff;
  border-radius: 25px;
  padding: 0px 30px;
  margin-left: -15px;
}
.supprt_search {
  border-radius: 25px;
}

// Responsive fixing
@media screen and (max-width: 991px) {
  .forum-image {
    width: 50%;
  }
  .main-background {
    background: none;
    margin-top: 10px;
    padding: 20px 30px;
  }
  .mainsection-content {
    background: #fff;
    padding: 10px;
    margin-top: 30px;
    border-radius: 14px 14px 0px 0;
  }
  .forumslider-header {
    font-size: 24px;
  }
  .ForumStatistics {
    display: none;
  }
}
