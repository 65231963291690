/*--- big screen ----*/

.pit-rate {
  background-color: "#000";
}

@media screen and (max-width: 1920px) and (min-width: 1601px) {
}

@media screen and (max-width: 1600px) and (min-width: 1400px) {
}

@media screen and (min-width: 1024px) and (max-width: 1281px) {
  .page-name {
    padding-left: 15px;
    padding-right: 15px;
  }
  .main-menu {
    margin-right: 20px;
  }
  .topbar .logo img {
    //width: 90px;
  }
  .header-inner {
    max-width: 70%;
  }
  .welcome-msg {
    height: auto;
    padding: 50px 0;
  }
  .col-1-5 {
    -webkit-flex: 0 0 25%;
    -moz-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    -o-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
    width: 25%;
  }

  .age-opt {
    width: 23%;
  }
  .search-match > h5 {
    max-width: 123px;
  }
  .search-match form button {
    font-size: 14px;
    padding: 8px 16px;
  }
  .right-pst-meta {
    width: 190px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1100px) {
  // .logo{width: 12%;}
  // .top-area{width: 86%;}
}

/*--- tablet landscape ---*/

@media screen and (max-width: 1024px) {
  .container {
    max-width: 1000px;
  }
  .top-area > ul > li > a {
    width: 40px;
  }
  .fixed-sidebar {
    display: none;
  }
  .profile-menu > li {
    // margin: 0 20px;
  }
  .top-search form button {
    padding: 10px;
  }
  .top-search form input {
    font-size: 12px;
    padding: 13px 15px;
  }
  .profile-menu > li {
    // margin: 0 15px;
  }
  .profile-menu > li > a {
    font-size: 13px;
  }

  .profile-menu > li {
    // margin: 0 15px;
  }
  .author-name {
    font-size: 18px;
  }
  .author-content {
    margin-top: 25px;
  }
  .profile-author-thumb > img {
    max-width: 130px;
    min-width: 130px;
    width: 130px;
  }
  .profile-author {
    margin-top: -145px;
  }
  .header-inner {
    max-width: 80%;
  }
  .page-header > figure img {
    max-width: 70%;
  }
  .badge-box > h5 {
    font-size: 14px;
  }
  .badge-box > p {
    font-size: 13px;
    line-height: 17px;
  }
  a.learnmore {
    font-size: 10px;
    right: 10px;
  }
  .description > h2 {
    font-size: 18px;
  }
  .employer-thmb {
    margin-right: 10px;
  }
  .employer-info {
    width: 74%;
  }
  .employer-info > h2 {
    font-size: 26px;
  }
  .friend-meta {
    width: 76%;
  }
  .friend-meta > h4 {
    width: 54%;
  }
  .widget .reg-comp-meta > ul > li > span {
    font-size: 11px;
  }
  .sidebar .widget ul.recent-photos li {
    width: 30.6%;
  }
  .dob-meta > h6 {
    font-size: 17px;
  }
  p {
    font-size: 13px;
  }
  .page-meta {
    width: 68.8%;
  }
  .your-page ul.page-publishes > li {
    width: 23.5%;
  }
  .dob-head > span {
    font-size: 12px;
  }
  .dob > span {
    font-size: 10px;
  }
  .friendz-meta > a {
    font-size: 13px;
  }
  .position-meta {
    width: 74%;
  }
  .position-meta > h6 {
    font-size: 13px;
  }
  .position-meta > a {
    font-size: 11px;
  }
  .position-meta > span {
    font-size: 11px;
    margin-top: 0;
  }
  .post-job > span {
    font-size: 20px;
  }
  .jalendar .jalendar-container .jalendar-pages > .header h1 {
    font-size: 12px;
  }
  .recent-links > li > figure {
    width: 70px;
  }
  .header-inner > form {
    max-width: 70%;
  }
  .friend-block > figure {
    width: 79px;
  }
  .we-video-info > ul li {
    margin-right: 26px;
  }
  .friend-name {
    width: 89%;
  }
  .getquot-baner > span {
    font-size: 24px;
  }
  .header-inner > h2 {
    font-size: 30px;
  }
  .header-inner > p {
    font-size: 13.5px;
  }
  .birthday-box .frnd-meta {
    width: 75%;
  }
  .quick-all {
    margin-top: 50px;
  }
  .quick-all-report > .row > div:first-child .quick-all,
  .quick-all-report > .row > div:nth-child(2) .quick-all,
  .quick-all-report > .row > div:nth-child(3) .quick-all {
    margin-top: 0;
  }
  .anlytc-meta {
    padding-left: 15px;
    width: 84%;
  }
  .anlytc-meta > h4 {
    font-size: 20px;
  }
  .is-helpful > em {
    float: left;
    margin-top: 10px;
    width: 100%;
  }
  .feature-videometa > h4 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .feature-videometa > p {
    font-size: 12px;
  }
  .color-box {
    font-size: 13px;
    padding: 15px 12px;
  }
  blockquote p {
    font-size: 18px;
    max-width: 90%;
  }
  .hourly-forecast > li {
    width: 82.7px;
  }
  .hourly-forecast > li > span {
    font-size: 15px;
  }
  .hourly-forecast > li > em {
    font-size: 12px;
  }
  .monthly-forecast.table tbody tr td {
    font-size: 12px;
  }
  .monthly-weather > ul {
    padding: 10px 0;
  }
  .weather-infos {
    padding: 0 20px;
  }
  .weather-infos > h3 {
    font-size: 18px;
  }
  .welcome-msg > figure {
    margin-bottom: 30px;
  }
  .we-login-register {
    padding: 20px 35px 35px;
  }
  .we-login-register::before {
    border-width: 39px 151px 0;
  }
  .col-1-5 {
    -webkit-flex: 0 0 25%;
    -moz-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    -o-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
    width: 25%;
  }
  .user-feature-info {
    width: 700px;
  }
  .author-meta {
    width: 66%;
  }
  .amount-select > li {
    width: 60px;
    line-height: 30px;
  }
  .pay-methods > li {
    font-size: 12px;
    padding: 7px 10px;
  }

  .container-fluid.ext-right {
    padding-right: 30px;
  }
  .featured-baner > h2 {
    font-size: 40px;
    width: 45%;
  }
  .search-match > form {
    width: 100%;
  }
  .select-gender > li img {
    width: 50px;
  }
  .search-match > h5 {
    max-width: 100%;
    text-align: left;
    width: 100%;
  }
  .age-opt {
    width: 15%;
  }
  .age-opt > p {
    display: none;
  }
  .age-opt .chosen-container {
    margin-bottom: 5px;
    width: 100% !important;
  }
  .search-match {
    padding: 20px;
  }
  .right-detailmeta > ul > li {
    font-size: 18px;
    height: 40px;
    line-height: 40px;
    width: 40px;
  }
  .oth-opt {
    width: 35%;
  }
  .pitrest-title {
    font-size: 22px;
  }
  .page-name {
    max-width: 104px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .tube-pst-meta {
    max-width: 48%;
    width: 48%;
  }
  .video-frame > h4 {
    font-size: 22px;
  }
  .follow {
    width: 100%;
  }
  .pittube-video .addnsend > a.main-btn {
    margin-top: 10px;
    padding: 6px 13px;
  }
  .chanel-avatar {
    width: 63%;
  }
  .pittube-video .addnsend {
    float: right;
    font-size: 13px;
    margin-top: 10px;
    text-align: center;
    width: 37%;
  }
  .pittube-video .addnsend > a {
    margin-left: 0;
  }
  .classic-pst-meta {
    max-width: 67%;
  }
  .right-pst-meta {
    width: 110px;
  }
  .pit-opt > li {
    margin-right: 15px;
  }
  .pit-post-deta > h4 {
    font-size: 16px;
  }
  .bg-feature {
    width: 67px;
  }
  .create-meta {
    padding-left: 5px;
    padding-right: 10px;
  }
  .con-pts {
    float: left;
    margin-top: 10px;
  }
  .sound-post-box > h4 {
    margin-right: 0;
  }
  .ac-container {
    width: 88% !important;
  }
  .friend-meta > h4 {
    width: 53%;
  }

  .about-user > figure img {
    max-width: 80px;
  }
  .singer-info > h6 {
    font-size: 16px;
  }
  .singer-info > span {
    font-size: 13px;
  }
  .audio-heading {
    font-size: 20px;
    width: 66%;
  }
  .playlist-box > ul li a {
    font-size: 13px;
    max-width: 73%;
  }
  .song-title {
    max-width: 70%;
  }
  .more-songs li figure {
    vertical-align: top;
  }
  .chater-info > figure img {
    max-width: 99px;
  }
  .upload-time {
    margin-top: 7px;
  }
  .my-status > figure img,
  .frnds-stories > li > figure img {
    max-width: 50px;
  }
  .story-user {
    width: 44%;
  }
  .user-profile .join-btn {
    left: 190px;
    bottom: 50px;
  }
  // .profile-menu{width: 65%;}
}

/*--- tablet view ---*/

@media screen and (max-width: 991px) {
  .central-meta {
    box-shadow: 5px 5px 20px 0px #ede9e900;
  }
  .folw-detail {
    float: inherit !important;
  }
  .profilemenuresp_align {
    text-align: -webkit-center;
  }
  // .container{max-width: 720px;}
  .responsive-header {
    display: block;
  }
  .topbar {
    display: none;
  }
  .side-panel {
    display: none;
  }
  footer .widget {
    margin-top: 40px;
  }
  footer .row > div:nth-child(1) .widget,
  footer .row > div:nth-child(2) .widget,
  footer .row > div:nth-child(3) .widget {
    margin-top: 0;
  }
  .sidebar > .widget,
  .sidebar > .advertisment-box,
  .sidebar > div {
    // float: left;
    // margin: 0 20px 20px 0;
    // width: 48.5%;
  }
  .sidebar > .widget:nth-child(2n),
  .sidebar > div:nth-child(2n) {
    margin-right: 0;
  }
  .sidebar .widget:last-child {
    margin-right: 0;
  }
  .salary-area {
    text-align: left;
  }
  .robo-meta h1 {
    font-size: 44px;
  }
  .sidebar.left {
    margin-bottom: 30px;
  }
  .sidebar.right {
    margin-top: 30px;
  }
  .sidebar > .advertisment-box:last-child {
    margin-right: 0;
  }
  .sidebar.manage .widget {
    width: 100%;
  }
  .comon-help > p {
    font-size: 16px;
  }
  .comon-help {
    margin-bottom: 30px;
  }
  .primary-support > .row > div:last-child .comon-help,
  .primary-support > .row > div:nth-last-child(2) .comon-help {
    margin-bottom: 0;
  }
  .pitnik-video-help > h4 {
    font-size: 30px;
  }
  .pitnik-video-help {
    padding-top: 30px;
  }
  .pitnik-video-help > p {
    font-size: 14px;
  }
  .item-upload {
    padding: 21px 12px;
  }

  .hourly-forecast > li {
    width: 79px;
  }
  .hourly-forecast > li > span {
    font-size: 15px;
  }
  .hourly-forecast > li > em {
    font-size: 12px;
  }
  .monthly-forecast.table tbody tr td {
    font-size: 12px;
  }
  .welcome-msg {
    display: inline-block;
    height: auto;
    padding: 30px 0;
    width: 100%;
  }
  .welcome-msg > figure {
    margin-bottom: 30px;
  }
  .fun-fact {
    margin-top: 20px;
  }
  .barcode {
    margin-top: 30px;
  }
  .we-login-register::before {
    display: none;
  }
  .we-login-register {
    border-radius: 5px;
  }
  .we-login-register {
    margin-bottom: 30px;
  }
  .user-feature-info {
    border: 1px solid #ede9e9;
    box-shadow: none;
    margin: 0px 10px 30px;
    width: 100%;
  }
  .job-search-form .c-form input {
    margin-bottom: 10px;
  }
  .friend-name {
    width: 93%;
  }
  .nave-area {
    margin-bottom: 20px;
    margin-top: 0;
  }
  .nave-area > li {
    margin-right: 20px;
  }
  .image-bunch {
    margin-bottom: 20px;
  }
  .coming-meta > h1 {
    font-size: 70px;
  }
  .status-story {
    padding: 30px;
  }
  .frnds-stories {
    display: flex;
  }
  .frnds-stories > li {
    margin-bottom: 0;
    text-align: center;
  }
  .frnds-stories > li:hover {
    border-radius: 15px;
  }
  .story-user {
    padding-left: 0;
  }
  .stories-users {
    margin-top: 0;
  }

  .user-add {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: medium none;
    line-height: initial;
    margin-top: 20px;
  }
  .notifi-meta {
    width: 80%;
  }
  .full-postmeta {
    margin-top: 20px;
  }
  .oth-opt {
    width: 29%;
  }
  .feature-title > h2 {
    font-size: 18px;
  }
  .feature-title > h4 {
    font-size: 18px;
  }
  .feature-title > h6 {
    font-size: 12px;
  }
  .tube-post .upload-time {
    font-size: 10px;
    margin-top: 7px;
  }
  .nave-area > li > a {
    font-size: 12.5px;
  }
  .pittube-video {
    margin-bottom: 30px;
  }
  .image-bunch > figure img {
    width: 100%;
  }
  .pit-post-deta {
    width: 64%;
  }
  .mesg-seting .set-title {
    margin-bottom: 0;
    margin-top: 10px;
  }
  .cart-sec table tr td {
    min-width: 260px;
  }
  .sound-post-box > h4 {
    max-width: 54%;
    overflow: hidden;
    padding-left: 10px;
    padding-right: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .sound-track-area {
    width: 100%;
  }
  .sound-right {
    margin-top: 20px;
    text-align: left;
    width: 100%;
  }
  .play-list {
    width: auto;
  }
  .sound-right > a.send-mesg {
    margin: 0 20px;
    width: auto;
  }
  .soundnik-meta {
    width: 90%;
  }
  .soundnik-meta > h1 {
    font-size: 30px;
  }
  .soundnik-meta > p {
    font-size: 14px;
  }
  .soundnik-meta > a.main-btn {
    padding: 7px 12px;
  }
  .sound-title {
    font-size: 16px;
  }
  .addnsend {
    float: left;
    margin-top: 20px;
  }
  .addnsend > a {
    margin-left: 0;
    margin-right: 10px;
  }
  .audio-heading {
    width: 64%;
  }
  .hourly-forecast > li {
    width: 77px;
  }
  .help-list {
    border: 1px solid #dadada;
    margin-bottom: 20px;
  }
  .getquot-baner > a {
    font-size: 15px;
    padding: 8px 30px;
  }
  .getquot-baner > span {
    font-size: 22px;
  }
  .message-writing-box > form > .text-area {
    width: 84.5%;
  }
  .chater-info > figure img {
    max-width: 99px;
  }
  .status-story {
    max-height: 100vh;
    overflow-y: scroll;
    position: static;
  }
  .story-user {
    width: auto;
  }
  .welcome-area {
    padding-top: 0;
  }
  .welcome-area > h2 {
    font-size: 23px;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .welcome-area > p {
    font-size: 15px;
  }
  .sec-heading.style9 > h2 {
    font-size: 28px;
  }
  .serv-box > h4 {
    font-size: 20px;
  }
  .serv-box {
    margin-bottom: 30px;
  }
  .blog-grid {
    margin-bottom: 30px;
  }
  .funfact {
    margin-bottom: 30px;
  }
  .price-box {
    margin-bottom: 30px;
  }
  .leter-meta > h2 {
    font-size: 28px;
  }
  .overlap22 {
    margin-top: 0;
  }
}

@media screen and (max-width: 812px) {
  .search-match {
    margin-left: 20px;
    width: calc(100% - 40px);
  }
  .chk {
    width: 18%;
  }
  .search-match form button {
    float: right;
    font-size: 14px;
    margin-top: -35px;
    padding: 8px 18px;
  }
  .oth-opt {
    margin-right: 12px;
    vertical-align: top;
    width: 38.8%;
  }
  .pitrest-pst-hding {
    font-size: 14px;
  }
  .col-1-5 {
    flex: 0 0 25%;
    max-width: 33.3%;
    width: 33.3%;
  }

  .friend-group > form > input {
    width: 49.6%;
  }
  .popup {
    padding: 15px;
    width: 70%;
  }
  .friend-group .change-photo {
    margin-bottom: 10px;
    padding: 10px;
  }
  .pit-post-deta {
    width: 57%;
  }
  .sidebar .friend-box {
    width: 48%;
  }
  .sidebar > .widget,
  .sidebar > .advertisment-box,
  .sidebar > div {
    width: 48.5%;
    margin-right: 15px;
  }
  .container-fluid.ext-50 {
    padding: 0 15px;
  }
  .playlist-box figure img {
    width: 35px;
  }
  .playlist-box > ul li a {
    font-size: 12px;
  }
  .audio-heading {
    padding: 0;
    width: 61%;
  }
  .audio-single .addnsend > a.main-btn {
    padding: 6px 13px;
  }
  .message-writing-box > form > .text-area {
    width: 81.5%;
  }
  .media > ul > li {
    width: 37px;
  }

  .userabout > p {
    font-size: 12px;
  }
  .userabout > ul > li {
    font-size: 12px;
  }
  .msg-pepl-list .nav-item > a > div h6 {
    font-size: 13px;
  }
  .message-head > h4 {
    font-size: 16px;
  }
  .active-user > div h6 {
    font-size: 14px;
  }
  .media > ul {
    max-height: 80px;
  }
  .frnds-stories > li {
    max-width: 100px;
    min-width: 100px;
    width: 100px;
  }
  .story-user > span {
    font-size: 13px;
  }
  .story-user > ins {
    font-size: 12px;
  }
  .status-story {
    max-height: 100vh;
    overflow-y: scroll;
    position: static;
  }
  .status-story > span {
    font-size: 19px;
    right: 15px;
    top: 10px;
  }
  .testi-meta > span {
    font-size: 16px;
  }
  .news-letter-bx {
    max-width: 100%;
    min-width: 100%;
  }
  .leter-meta > p {
    font-size: 15px;
  }
  .profile-menu {
    width: 100%;
  }
  .profile-menu > li > a.active::after {
    display: none;
  }
  .folw-detail {
    padding-left: 15px;
  }
}

@media screen and (max-width: 768px) {
  .privacy-slider {
    padding-top: 15px !important;
  }

  .gap {
    padding: 40px 0;
  }
  p {
    font-size: 13px;
    line-height: 20px;
  }
  .page-header {
    padding-top: 30px;
  }
  .header-inner {
    max-width: 100%;
  }
  .location > li p {
    width: 83%;
    font-size: 13px;
  }
  .list-style > li,
  .colla-apps > li a {
    font-size: 13px;
  }
  footer .widget {
    margin-top: 40px;
  }
  footer .row > div:nth-child(1) .widget,
  footer .row > div:nth-child(2) .widget,
  footer .row > div:nth-child(3) .widget {
    margin-top: 0;
  }
  .sidebar .widget ul.recent-photos li > a,
  .sidebar .widget ul.recent-photos li > a img {
    display: inline-block;
    width: 100%;
  }
  .error-meta {
    padding-left: 30px;
  }
  .title2 {
    font-size: 20px;
  }
  .we-login-register {
    margin-bottom: 40px;
  }
  .container-fluid.ext-padding {
    padding-left: 40px;
    padding-right: 40px;
  }

  .search-match {
    margin-left: 0;
    margin-top: 30px;
    width: 100%;
  }
  .overlap {
    margin-top: 0;
  }
  .chk {
    width: 20%;
  }
  .oth-opt {
    margin-right: 0;
    width: 39%;
  }
  .col-1-5 {
    flex: 0 0 25%;
    max-width: 25%;
    width: 25%;
  }
  .classic-pst-meta {
    max-width: 78%;
  }
  .pittop-meta {
    left: 15px;
    bottom: 15px;
  }

  // .gray-bg {
  //   background: #a5dad0;
  // }
}

@media screen and (max-width: 767px) {
  .PrivacyMenu a {
    white-space: nowrap !important;
    text-overflow: inherit !important;
    overflow: initial !important;
    margin-bottom: 0 !important;
  }
  .PrivacyMenu {
    display: flex;
    overflow: auto;
  }
  .container {
    max-width: 600px;
  }
  .profile-controls {
    bottom: auto;
    padding: 4px 9px;
    top: 0;
  }
  .profile-controls > li > a,
  .profile-controls > li > div {
    font-size: 13px;
    height: 30px;
    line-height: 30px;
    width: 30px;
  }
  .author-content {
    margin-top: 22px;
  }
  .profile-author-thumb > img {
    max-width: 100px;
    min-width: 100px;
    width: 100px;
  }
  .profile-author {
    margin-top: -109px;
  }
  .profile-menu {
    text-align: center;
    padding-left: 0;
  }

  footer .row > div:nth-child(2) .widget,
  footer .row > div:nth-child(3) .widget {
    margin-top: 40px;
  }
  .error-meta h1 {
    font-size: 40px;
  }
  .big-font > span {
    font-size: 80px;
  }
  .robo-meta h1 {
    font-size: 40px;
  }
  .robo-meta h1 {
    display: inline-block;
    font-size: 38px;
    font-weight: 500;
  }
  .eror-robot {
    height: auto;
  }
  .eror-robot .error-page {
    padding-top: 0;
  }
  .robo-image {
    height: auto;
  }
  .robo-meta {
    height: auto;
  }
  .robo-meta h1 {
    font-size: 32px;
  }
  .robo-meta span {
    font-size: 19px;
  }
  .eror.eror500 {
    height: auto;
  }
  .eror500 .big-font {
    height: 100%;
  }
  .error-page500 .error-meta h1 {
    font-size: 24px;
  }
  .sidebar.left {
    margin-bottom: 30px;
  }
  .sidebar.right {
    margin-top: 30px;
  }
  .event-title > h4 {
    font-size: 14px;
  }
  .forum-open table tr td {
    min-width: 150px;
  }
  .date-n-reply > a {
    margin-top: 10px;
  }
  .forum-open table tr td.topic-detail {
    min-width: 350px;
  }
  .set-title > h5,
  .set-title > h6 {
    font-size: 16px;
  }
  .onoff-options .setting-row > span {
    font-size: 12px;
  }
  .onoff-options .setting-row > p {
    font-size: 12px;
  }
  .set-title > span {
    font-size: 12px;
    width: 100%;
  }
  .nav-tabs--left .nav-link {
    font-size: 13px;
  }
  form button,
  form a.main-btn,
  form a.main-btn2,
  form a.main-btn3 {
    font-size: 12px;
    padding: 6px 20px;
  }
  .account-delete > div > button {
    font-size: 12px;
    padding: 6px 14px;
  }
  .account-delete > div > span {
    font-size: 12px;
  }
  .account-delete > f5 {
    font-size: 16px;
  }
  .d-flex .tab-content {
    padding-left: 30px;
  }
  .help-topic > h2 {
    font-size: 20px;
    margin-bottom: 30px;
  }
  .assistant-name {
    width: 60%;
  }
  .popup {
    width: 90%;
  }
  .friend-group .change-photo {
    border: 0 none;
    padding: 0;
  }
  .friend-group > form > input {
    margin-bottom: 10px;
  }
  .friend-group {
    padding: 10px 0;
  }
  .item-box .item-upload {
    height: 256px;
    padding-top: 70px;
  }
  .sugestd-photo-caro > li {
    width: 50px;
  }
  .smal-box {
    padding: 10px;
  }
  .sugested-photos {
    margin: 15px 0;
  }
  .upload-boxes {
    padding-bottom: 20px;
  }
  .color-box {
    margin-bottom: 10px;
  }
  .ico-box {
    margin-bottom: 20px;
  }
  .hourly-forecast > li {
    margin-bottom: 5px;
    width: 24.4%;
  }
  .we-login-register {
    margin-bottom: 40px;
  }
  .welcome-msg > h1 {
    font-size: 30px;
  }
  .we-login-register {
    margin-bottom: 30px;
  }
  .app-download {
    width: 78%;
  }
  .container-fluid.ext-padding {
    padding-left: 40px;
    padding-right: 40px;
  }
  .friend-name {
    width: 90%;
  }
  .author-meta {
    margin-top: 10px;
    padding-left: 0;
    text-align: left;
    width: 100%;
  }
  .author-meta > span {
    padding-left: 0;
  }
  .user-figure > a {
    padding-left: 0;
  }
  .author-meta > h5 {
    margin-bottom: 4px;
  }
  .jobbox-title {
    width: 60%;
  }
  .jobbox-title > h5 {
    font-size: 19px;
  }
  .nave-area > li {
    margin-bottom: 6px;
  }
  .mega-menu {
    left: 0;
    right: auto;
  }
  .coming-meta > h1 {
    font-size: 60px;
    padding-right: 0;
  }
  .amount-select {
    margin-bottom: 15px;
    width: 100%;
  }
  .c-form.pay-card input {
    margin-bottom: 10px;
  }
  .logout-f {
    margin-top: 40px;
  }
  .search-match form button {
    float: right;
  }
  .oth-opt {
    width: 35%;
  }
  .filter-ajex {
    padding-left: 0;
  }
  .range-slider {
    margin-top: 24px;
    float: left;
    width: 100%;
  }
  .filter-ajex > li {
    margin-right: 11px;
  }
  .add-meta > h5 {
    text-align: center;
    width: 100%;
    font-size: 30px;
  }
  .add-meta > h5 span {
    width: 100%;
  }
  .add-meta > i {
    display: inline-block;
    margin: 0 15px 0 0;
    text-align: center;
  }
  .add-meta {
    text-align: center;
  }
  .add-meta > a {
    vertical-align: middle;
  }
  .featured-baner > h2 {
    font-size: 35px;
    margin-top: 30px;
    width: 60%;
  }
  .sub-links > a {
    padding: 7px 10px;
  }
  .pit-post-deta {
    width: 53%;
  }
  .col-1-5 {
    flex: 0 0 25%;
    max-width: 33.3%;
    width: 33.3%;
  }

  .pittop-meta > figure img {
    max-width: 70px;
  }
  .pittop-meta {
    bottom: 10px;
    left: 10px;
  }
  .pittop-meta > div h6 {
    font-size: 18px;
  }
  .pittop-meta > div span {
    font-size: 13px;
  }

  .video-frame .pit-opt {
    width: 100%;
  }
  .uploadtime {
    float: left;
    font-size: 12px;
  }
  .video-frame > h4 {
    font-size: 20px;
    padding-right: 0;
  }
  .channl-author {
    width: 60%;
  }
  .channl-author > h5 {
    font-size: 18px;
  }
  .channl-author > span {
    font-size: 12px;
  }
  .channl-author > em {
    font-size: 12px;
  }
  .classic-pst-meta {
    max-width: 73%;
  }
  .col-sm-6 .sidebar .friend-box {
    width: 100%;
  }
  .col-sm-6 .sidebar > .widget,
  .col-sm-6 .sidebar > .advertisment-box,
  .col-sm-6 .sidebar > div {
    width: 100%;
    margin-right: 0;
  }
  .post-title .number {
    margin-top: 20px;
  }
  .help-box {
    margin-bottom: 20px;
  }
  .no-margin {
    margin: 0;
  }
  .links-tab li.nav-item {
    margin-right: 34px;
  }
  .links-tab li.nav-item > a {
    font-size: 13px;
  }
  .sound-user > figure img {
    max-width: 60px;
  }
  .sound-post-box > span {
    float: right;
  }
  .text-caro-meta > h1 {
    font-size: 36px;
    margin-bottom: 0;
  }
  .text-caro-meta > p {
    font-size: 14px;
  }
  .text-caro-meta > span {
    font-size: 16px;
  }
  .welcome-area {
    margin-bottom: 20px;
  }
  .count {
    margin-top: 40px;
    width: 75px;
  }
  .counter-meta {
    padding-right: 0;
  }
  .counter,
  .count i {
    font-size: 26px;
  }
  .counter-meta > h2 {
    font-size: 16px;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .tab-sec {
    margin-bottom: 30px;
  }
  .team > p {
    margin-bottom: 0;
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .team {
    margin-bottom: 40px;
  }
  .pricings > h1 span {
    right: 0;
  }
  .pricings > h1 {
    font-size: 30px;
  }
  .pricings > h2 {
    font-size: 25px;
  }
  .price-features > li {
    font-size: 13px;
    margin-bottom: 10px;
  }
  .leter-meta {
    margin-bottom: 20px;
    text-align: center;
  }
  .leter-meta > p {
    max-width: 100%;
  }
  .folw-detail {
    padding-bottom: 10px;
    padding-left: 0;
    text-align: center;
    width: 100%;
  }
  .user-profile .join-btn {
    bottom: 20px;
    font-size: 12px;
    left: 30px;
    z-index: 9;
  }
  .user-profile .pit-rate {
    top: 50px;
  }
  .user-profile .pit-rate > li {
    font-size: 13px;
  }
}

@media screen and (max-width: 667px) {
  .oth-opt {
    width: 34%;
    vertical-align: top;
  }
  .search-match form button {
    font-size: 13px;
    margin-top: -33px;
    padding: 5px 20px;
  }
  .loc {
    margin-right: 0;
  }
  .pit-post-deta {
    width: 53%;
  }
  .featured-baner > h3 {
    font-size: 18px;
    left: 15px;
  }
  .pitred-links > a {
    padding: 6px 10px;
  }
  .pitred-links > ul {
    padding-left: 10px;
    width: 82%;
  }
  .pitred-links > ul > li {
    font-size: 13px;
    margin-right: 17px;
  }
  .pitred-links > ul > li:last-child {
    margin-right: 0;
  }
  .number {
    width: 101px;
  }
  .number > span {
    width: 30px;
  }
  .right-pst-meta {
    float: right;
  }
  .ac-container {
    width: 85% !important;
  }
  .already-gallery {
    width: 90%;
  }

  .post-comt-box form textarea {
    height: 40px;
  }
  .postbox > figure {
    float: right;
    max-width: 80px;
  }
  .popup-meta .friend-info {
    float: left;
  }
  .postbox .friend-name {
    width: 85%;
  }
  .popup-meta .friend-info > figure {
    vertical-align: top;
  }
  .copy-email {
    position: absolute;
    right: 100px;
    top: 103px;
  }
  .postbox .we-video-info > button {
    margin-top: 8px;
  }
  .popup.post-sharing {
    width: 85%;
  }
  .share-to-other > span,
  .copy-email > span {
    font-size: 12px;
  }
  .friend-name > span {
    font-size: 11px;
  }
  .form-radio .radio > label {
    font-size: 12px;
  }
  .form-radio .radio {
    margin-right: 15px;
  }
  .form-radio .radio:last-child {
    margin-right: 0;
  }
  .m-live-call > figure img {
    max-width: 90px;
  }
  .call-box > h6 {
    font-size: 20px;
  }
  .call-box > span {
    font-size: 12px;
  }
  .call-box {
    display: inline-block;
    vertical-align: middle;
  }
  .m-live-call > figure {
    width: auto;
  }
  .yesorno > a {
    font-size: 17px;
    height: 40px;
    line-height: 40px;
    margin: 0 10px;
    width: 40px;
  }
  .m-live-call {
    padding: 15px;
    width: 359px;
  }
  .call-box > i {
    font-size: 30px;
  }
  .message-users {
    width: 100%;
  }
  .tab-content.messenger {
    width: 100%;
    border-top: 2px solid #3ca9fc;
  }
  .chater-info {
    border-radius: 0;
    border-top: 2px solid #3ca9fc;
  }
  .media > ul > li {
    width: 70px;
  }
  .text-caro-meta > h1 {
    font-size: 30px;
    padding-bottom: 20px;
    width: 100%;
  }
  .sec-heading.style9 > h2 {
    font-size: 24px;
  }
  .blog-title {
    font-size: 18px;
    vertical-align: middle;
  }
  .testi-silver > span::before {
    border-top: 276px solid transparent;
  }
}

@media screen and (max-width: 640px) {
  .profile-menu > li > a.active::after {
    border-bottom: 6px solid #fa6342;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
  }
  .profile-menu {
    line-height: 40px;
  }
  footer .row > div:nth-child(2) .widget,
  footer .row > div:nth-child(3) .widget {
    margin-top: 40px;
  }
  .page-header > figure img {
    max-width: 100%;
  }
  .align-right {
    margin-left: 10px;
  }
  .pit-frnz-meta,
  .pit-groups-meta,
  .pit-pages-meta {
    width: 89%;
  }
  .typography > .central-meta > button.main-btn2 {
    font-size: 12px;
    padding: 6px 15px;
  }
  .mega-menu {
    left: -40px;
  }
  .chk {
    width: 23%;
  }
  .chk .checkbox > label {
    font-size: 12px;
  }
  .oth-opt {
    width: 29%;
  }
  .feature-title > h6 {
    font-size: 18px;
  }
  .sidebar > .widget,
  .sidebar > .advertisment-box,
  .sidebar > div {
    width: 100%;
    margin-right: 0;
  }
}
@media screen and (max-width: 568px) {
  .row.merged20 > div {
    padding: 0 15px;
  }
  .container {
    max-width: 540px;
  }
  .sidebar .widget:last-child {
    margin-bottom: 20px;
  }
  .header-inner > p {
    width: 100%;
  }
  .big-font > span {
    writing-mode: inherit;
  }
  .big-font {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    height: auto;
    line-height: initial;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .eror {
    height: auto;
  }
  .eror .bg-image {
    height: 100%;
  }
  .error-meta h1 {
    font-size: 30px;
  }
  .error-meta p {
    margin-bottom: 30px;
  }
  .error-meta .main-btn {
    margin-bottom: 30px;
  }
  .error-meta {
    padding-left: 0;
    text-align: center;
  }
  .eror.eror500 {
    height: auto;
  }
  .eror500 .big-font > span {
    color: #535165;
    font-size: 50px;
    padding-top: 30px;
  }
  .error-page500 .error-meta {
    padding-top: 20px;
  }
  .title-block form {
    // margin-bottom: 10px;
    // margin-top: 15px;
  }
  .title-block .select-options {
    margin-bottom: 8px;
  }
  .event-time {
    padding: 3px 0;
    text-align: left;
  }
  .header-inner > h2 {
    font-size: 26px;
  }
  .post-meta.searched .detail {
    width: 66%;
  }
  .quick-all-report > .row > div:nth-child(2) .quick-all,
  .quick-all-report > .row > div:nth-child(3) .quick-all {
    margin-top: 40px;
  }
  .pitnik-title > h1 {
    font-size: 28px;
  }
  .pitnik-title > p {
    font-size: 14px;
    max-width: 90%;
  }
  .pitnik-title {
    margin-bottom: 40px;
  }
  .addgroup {
    margin-bottom: 30px;
  }
  .btn.btn-secondary {
    font-size: 12px;
  }
  .fav-play {
    margin-bottom: 30px;
  }
  .story-postbox > .row > div {
    width: 25%;
  }
  .app-download > span {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .app-download {
    margin-left: 15px;
    width: 75%;
  }
  .fun-fact > .row > div {
    width: 33%;
  }
  .user-figure {
    text-align: center;
  }
  .author-meta {
    text-align: center;
  }
  .author-meta > span {
    display: inline-block;
    width: 100%;
  }
  .user-inf-meta {
    margin-top: 10px;
  }
  .job-progres > li {
    margin-right: 35px;
  }
  .mega-menu {
    left: -150px;
    width: 507px;
  }

  .we-comment {
    width: 91%;
  }
  .post-comt-box {
    width: 90%;
  }
  .log-user > .row > div {
    width: 33%;
  }
  .sub-total > .row > div {
    width: 33%;
  }
  .user-add {
    background: #fff none repeat scroll 0 0;
    border: 1px dashed #afafaf;
    line-height: 140px;
    margin-top: 0;
  }
  .heart-pnts > a {
    font-size: 12px;
    margin-right: 10px;
  }
  .heart-pnts > div span {
    font-size: 12px;
  }
  .oth-opt {
    width: 24.9%;
  }
  .more-about table.overview.table-responsive {
    display: block;
  }
  .pit-post-deta {
    width: 68%;
  }
  .buttons {
    margin-top: 10px;
    width: 100%;
  }
  .buttons > a {
    display: inline-block;
    margin-bottom: 0;
  }
  .col-1-5 {
    max-width: 50%;
    width: 50%;
  }
  .feature-chanel {
    margin-bottom: 20px;
    margin-top: 0;
  }
  .tube-post .upload-time {
    font-size: 12px;
  }
  .follow > li {
    font-size: 12px;
    margin-right: 20px;
  }
  .follow > li:last-child {
    margin-right: 0;
  }
  .classic-pst-meta {
    max-width: 66%;
  }

  .pit-post-deta {
    margin-top: 15px;
    padding-left: 0;
    width: 100%;
  }
  .right-pst-meta {
    float: left;
    text-align: left;
    width: 100%;
  }
  .number {
    position: absolute;
    right: 0;
    top: 0;
  }
  .pit-post .users-thumb-list {
    margin-left: 20px;
  }
  .sidebar > .widget,
  .sidebar > .advertisment-box,
  .sidebar > div {
    margin-right: 12px;
  }
  .search-tab .nav-tabs .nav-item > a {
    padding: 10px 14px;
  }
  .post-title .number {
    bottom: 10px;
    position: absolute;
    right: 25px;
    top: auto;
  }
  .audio-heading {
    margin-top: 15px;
    width: 100%;
  }
  .monthly-forecast.table tbody tr td {
    font-size: 12px;
    min-width: 130px;
  }
  .hourly-forecast > li {
    width: 24.3%;
  }
  .popup.post-sharing {
    width: 90%;
  }
  .links-tab li.nav-item {
    margin-right: 24px;
  }
  .music-track canvas {
    width: 100%;
  }
  .text-caro-meta h1 a {
    display: inline-block;
    width: 100%;
  }
  .text-caro-meta h1 {
    display: inline-block;
    font-size: 28px;
    line-height: 38px;
    width: 100%;
  }
  .count {
    width: 120px;
  }

  .full-postmeta > h4 {
    font-size: 24px;
  }
  .tab-section.ruby .nav.nav-tabs.single-btn {
    margin-bottom: 20px;
    margin-top: 0;
    width: 100%;
  }
  .tab-section.ruby .nav.nav-tabs.single-btn > li {
    display: inline;
  }
  .single-btn > li > a {
    padding: 7px 15px;
  }
  .tab-section.ruby .tab-content {
    width: 100%;
  }
  #desc .more-pix .main-title {
    font-size: 20px;
  }
  .prices.style4 > ins span {
    font-size: 20px;
  }
}

@media screen and (max-width: 480px) {
  .sidebar .widget ul.recent-photos li > a,
  .sidebar .widget ul.recent-photos li > a img {
    display: inline-block;
    width: 100%;
  }
  .we-video-info > ul li:last-child {
    margin-top: 20px;
    width: 100%;
  }
  .we-video-info > ul li {
    margin-right: 32px;
  }
  .friend-name {
    width: 87%;
  }
  .newpst-input {
    width: 85%;
  }
  .like-dislike {
    top: 40%;
  }
  .post-meta .detail > span {
    font-size: 21px;
  }
  .we-comment {
    width: 87%;
  }
  .forum-list table tbody tr td:first-child {
    min-width: 350px;
  }
  .getquot-baner {
    padding: 20px;
    text-align: center;
  }
  .getquot-baner > span {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 20px;
    text-align: center;
  }
  .getquot-baner > a {
    float: none;
    font-size: 13px;
    padding: 8px 24px;
  }
  .forum-list table tbody tr td > a {
    font-size: 14px;
  }
  .forum-list table tbody tr td p {
    font-size: 13px;
  }
  a.addnewforum {
    font-size: 13px;
  }
  .story-postbox > .row > div {
    width: 25%;
  }
  .notifi-meta {
    width: 68%;
  }
  .notifi-meta > p {
    font-size: 12px;
  }
  .notifi-meta > span {
    font-size: 11px;
  }
  .search-meta > span {
    font-size: 16px;
  }
  .mh-head .mh-text > a img {
    width: 95px;
  }
  .mh-head .mh-text {
    margin-top: 8px;
  }
  .pit-frnz-meta,
  .pit-groups-meta,
  .pit-pages-meta {
    width: 88%;
  }
  // .d-flex {
  //     display: inline-block !important;
  // }
  .nav-tabs--vertical {
    display: inline-block;
    padding-right: 0;
    width: 100%;
  }
  .d-flex > ul > li.nav-item {
    display: inline-block;
    width: 49%;
  }
  .d-flex .tab-content {
    border-left: 0 none;
    padding-left: 0;
  }
  .privacy .d-flex .tab-content {
    padding: 20px;
  }
  .comon-help > h4 {
    font-size: 19px;
    margin-top: 10px;
  }
  .assistant-name {
    padding-left: 0;
    width: 100%;
  }
  .post-dated {
    width: 48%;
  }
  .is-helpful > span {
    display: inline-block;
    margin-bottom: 10px;
    margin-right: 0;
    width: 100%;
  }
  .addgroup .item-upload {
    display: inline-block;
    height: auto;
    padding: 20px;
  }
  .addgroup {
    height: auto;
  }
  .item-box .item-upload {
    height: auto;
    padding-top: 25px;
  }
  a.main-btn,
  a.main-btn2,
  a.main-btn3 {
    padding: 7px 25px;
  }
  .hourly-forecast > li {
    margin-bottom: 3px;
    width: 24.3%;
  }
  .weather-area > h2 {
    font-size: 23px;
  }
  .time {
    float: none;
    margin-top: 0;
    padding-bottom: 20px;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    width: 100%;
  }
  .app-download {
    margin-left: 7px;
    width: 73%;
  }
  .app-download > span {
    font-size: 14px;
    font-weight: 500;
  }
  .barcode > figure {
    width: 80px;
  }
  .welcome-msg {
    text-align: center;
  }
  .gap.signin .bg-image {
    height: 100%;
  }
  .gap.signin::before {
    height: 100%;
  }
  .we-video-info > ul li:last-child {
    margin-top: 0;
    width: auto;
  }
  .job-progres > li {
    font-size: 14px;
    margin-right: 8px;
  }
  .nave-area > li {
    margin-right: 17px;
  }
  .mega-menu {
    left: -122px;
    width: 414px;
  }
  .coming-meta > h1 {
    font-size: 50px;
  }

  .pittop-meta {
    bottom: 10px;
    left: 10px;
  }
  .pittop-meta > figure img {
    max-width: 70px;
  }
  .pittop-meta > div {
    max-width: 60%;
  }
  .pittop-meta > div h6 {
    font-size: 16px;
  }
  .pittop-meta > div span {
    font-size: 13px;
  }
  .don-form > h4 {
    font-size: 20px;
    width: 50%;
  }
  .don-form > .chosen-container {
    width: 50% !important;
  }
  .don-form .c-form > input {
    width: 100%;
  }
  .pay-methods > li {
    margin-bottom: 5px;
  }
  .our-moto > p {
    font-size: 13px;
  }
  .our-moto {
    padding: 15px;
  }
  .social-name {
    margin-bottom: 20px;
  }
  .forum-warper > h4 {
    font-size: 16px;
  }
  .total > em {
    font-size: 14px;
  }
  .user-add {
    line-height: 130px;
  }
  .row.border-center > div {
    padding: 0 45px;
  }

  .sub-links > a {
    padding: 4px 10px;
  }
  .heart-pnts > div > i {
    font-size: 39px;
  }
  .sub-links {
    padding: 5px 10px;
  }
  .featured-baner > h2 {
    font-size: 25px;
    left: 20px;
  }
  .chk {
    width: 60%;
  }
  .age-opt {
    width: 40%;
  }
  .oth-opt {
    vertical-align: middle;
    width: 55%;
  }
  .search-match form button {
    margin-top: -160px;
  }
  .sidebar .friend-box {
    width: 100%;
  }
  .range-slider {
    width: 100%;
  }
  .add-meta > h5 {
    font-size: 21px;
  }
  .pit-ad-banner {
    padding: 15px;
  }
  .add-meta > h5 span {
    font-size: 14px;
    line-height: initial;
    vertical-align: top;
  }
  .add-meta > a {
    font-size: 14px;
    padding: 6px 18px;
    width: auto;
  }
  .add-meta > i {
    max-width: 40px;
  }
  .full-postmeta {
    padding: 20px;
  }
  .left-detail-meta > h4 {
    font-size: 20px;
  }
  .more-about .overview .table td,
  .table th {
    min-width: 200px;
    font-size: 13px;
  }
  .table td,
  .table th {
    font-size: 13px;
  }
  .pit-post-deta {
    margin-top: 0;
    padding-left: 10px;
    width: 67%;
  }
  .buttons > a {
    display: block;
    margin-bottom: 10px;
  }

  .classic-post > figure,
  .classic-post > figure img {
    width: 100%;
  }
  .classic-pst-meta {
    margin-top: 20px;
    max-width: 100%;
    padding-left: 0;
  }
  .pst-change-style {
    margin-bottom: 10px;
  }
  .pst-change-style .chosen-container {
    width: 73% !important;
  }
  .number {
    bottom: 0;
    top: auto;
  }
  .pit-post-deta > h4 {
    font-size: 15px;
  }
  .pitred-links > ul {
    margin-top: 10px;
  }

  .sidebar > .widget,
  .sidebar > .advertisment-box,
  .sidebar > div {
    margin-right: 0;
    width: 100%;
  }
  .search-tab .nav-tabs .nav-item > a {
    padding: 10px 11px;
    font-size: 14px;
  }
  .ac-container {
    width: 84% !important;
  }
  .links-tab {
    line-height: initial;
    padding: 10px 0;
  }
  .links-tab li.nav-item > a.active::after {
    display: none;
  }
  .links-tab li.nav-item {
    line-height: 30px;
    margin-right: 38px;
  }
  .links-tab li.nav-item > a span {
    top: 0;
  }
  .friend-group > form > input {
    width: 49.5%;
  }
  .soundnik-meta > h1 {
    font-size: 20px;
  }
  .soundnik-meta > p {
    display: none;
  }
  .soundnik-meta > a.main-btn {
    font-size: 11px;
    padding: 6px 12px;
  }
  .text-caro-meta {
    width: 100%;
  }
  .profile-menu > li {
    // margin: 0 11px;
  }
  .post-as {
    margin-top: 20px;
    width: 100%;
  }
  .extras {
    float: left;
    margin-top: 20px;
  }
  .prod-detail .full-postmeta .shopnow {
    margin-top: 20px;
  }
}

@media screen and (max-width: 420px) {
  .profile-author-thumb > img {
    max-width: 80px;
    min-width: 80px;
    width: 80px;
  }

  .user-profile .join-btn {
    font-size: 12px;
    left: auto;
    padding: 4px 9px;
    right: 10px;
  }
  .user-profile .pit-rate {
    right: 10px;
    top: 10px;
  }
  .edit-pp,
  .edit-dp {
    height: 30px;
    line-height: 30px;
    width: 30px;
  }

  .profile-author {
    margin-left: 0;
    margin-top: -85px;
    text-align: left;
  }
  .profile-menu {
    padding: 0;
    text-align: left;
  }
  .profile-menu > li {
    // margin: 0 19px;
  }
  .profile-menu > li:first-child {
    margin-left: 0;
  }
  .profile-controls {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border-radius: 4px;
    bottom: -45px;
    top: auto;
  }
  .page-header > figure img {
    max-width: 100%;
  }
  .eror {
    height: 100vh;
  }
  .eror .bg-image {
    height: 100vh;
  }
  .error-page {
    padding-top: 80px;
  }
  .header-inner > form {
    max-width: 100%;
  }
  .faq-area > h4 {
    font-size: 20px;
  }
  .sidebar.left .widget:last-child {
    margin-bottom: 0;
  }
  .post-meta.searched .linked-image {
    float: left;
    margin-left: 0;
  }
  .post-meta.searched .detail {
    width: 100%;
  }
  .pit-frnz-meta,
  .pit-groups-meta,
  .pit-pages-meta {
    width: 85%;
  }
  .is-helpful > a {
    padding: 9px 17px;
  }
  .share-article > a {
    line-height: 30px;
    width: 30px;
  }
  .central-meta.padding30 {
    padding: 20px 15px;
  }
  .hourly-forecast > li {
    width: 24.2%;
  }
  .fun-box > h6 {
    font-size: 12.6px;
  }
  .fun-box > span {
    font-size: 13px;
  }
  .welcome-msg > p {
    font-size: 14px;
    width: 100%;
  }
  .welcome-msg > h1 {
    font-size: 26px;
  }
  .barcode > figure {
    margin-bottom: 20px;
    width: 95px;
  }
  .app-download > span {
    font-size: 12px;
  }
  .app-download {
    margin-left: 0;
    width: 100%;
  }

  .sharing-tools .we-video-info > ul {
    width: 60%;
  }
  .sharing-tools .share-to-other {
    width: 40%;
  }
  .friend-name {
    width: 86%;
  }
  .we-video-info > .users-thumb-list {
    width: 100%;
    margin-top: 10px;
  }
  .loc-cate .cate {
    float: left;
    margin: 5px 0;
  }
  .post-meta .we-video-info > ul {
    width: 100%;
  }
  .share-to-other > ul li {
    margin-right: 7px;
  }
  .job-detail > ul li span {
    width: 93%;
  }
  .apply-bttons {
    margin-bottom: 0;
    margin-top: 0;
  }
  .related-links > li {
    width: 100%;
  }
  .mega-menu {
    left: -233px;
    width: 414px;
  }
  .countdown > li {
    width: 80px;
    line-height: 80px;
    height: 80px;
  }
  .coming-meta > form {
    width: 90%;
  }
  .post-comt-box {
    width: 89%;
  }
  .log-user > .row > div {
    width: 50%;
  }
  .user-add {
    margin-top: 20px;
  }
  .container-fluid.ext-right {
    padding-left: 15px;
    padding-right: 15px;
  }
  .heart-pnts {
    width: 100%;
  }
  .featured-baner > h2 {
    margin-top: 0;
    padding-left: 10px;
    position: unset;
    width: 100%;
  }
  .chk {
    width: 50%;
  }
  .oth-opt {
    width: 54%;
  }
  .search-match > h5 {
    margin-bottom: 10px;
    padding-right: 0;
  }
  .right-detailmeta > ul > li {
    font-size: 18px;
    height: 35px;
    line-height: 35px;
    width: 35px;
  }
  .share {
    float: left;
    margin-top: 20px;
  }
  .bottom-meta {
    margin-top: 0;
  }
  .pit-post > figure {
    margin-bottom: 10px;
  }
  .pit-post {
    text-align: center;
  }
  .pit-post-deta {
    padding-left: 0;
    width: 100%;
  }
  .pittop-meta > figure img {
    max-width: 40px;
  }
  .chanel-avatar > figure img {
    max-width: 60px;
  }
  .channl-author > h5 {
    font-size: 16px;
  }
  .follow {
    margin-top: 10px;
  }
  .video-js.vjs-default-skin.vjs-paused {
    height: 180px !important;
  }
  .uploadtime {
    float: right;
    margin-top: -25px;
  }
  .pit-opt > li {
    margin-bottom: 10px;
    margin-right: 20px;
  }
  .video-frame > h4 {
    font-size: 18px;
    margin-top: 10px;
  }
  .number {
    bottom: auto;
    top: 0;
    width: 32px;
  }
  .pst-change-style .chosen-container {
    width: 70% !important;
  }
  .ac-container {
    width: 100% !important;
  }
  .ac-container.ac-play-container {
    width: 50px !important;
  }
  .hourly-forecast > li {
    width: 32.5%;
  }
  .friend-group > form > input {
    width: 100%;
  }
  .my-status > figure img,
  .frnds-stories > li > figure img {
    max-width: 50px;
  }
  .my-status > a.main-btn {
    padding: 3px 10px;
  }
  .fc-header-right {
    margin-top: -110px;
  }
}

@media screen and (max-width: 375px) {
  .profile-menu > li {
    // margin: 0 16px;
  }
  .profile-menu > li:nth-last-child(2) {
    margin-left: 0;
  }
  .newpst-input {
    width: 82%;
  }
  .friend-name {
    width: 85%;
  }
  .post-comt-box {
    width: 87%;
    padding-left: 0;
  }
  .post-meta .detail {
    width: 62%;
  }
  .post-meta .detail > span {
    font-size: 19px;
  }
  .we-comment {
    width: 85%;
  }
  .birthday-box .frnd-meta {
    width: 74%;
  }
  .frnd-name > a {
    font-size: 13.5px;
  }
  .c-form.mng-page > button {
    font-size: 13px;
    padding: 8px 14px;
  }
  .pit-frnz-meta,
  .pit-groups-meta,
  .pit-pages-meta {
    width: 83%;
  }
  .popup.direct-mesg {
    width: 90%;
  }
  .hourly-forecast > li {
    width: 32.4%;
  }
  .employer-info {
    text-align: center;
    width: 100%;
  }
  .employer-thmb {
    display: block;
    margin-right: 0;
    text-align: center;
  }
  .employer-info > h4 {
    text-align: left;
  }
  .salary-area {
    margin-top: 10px;
  }
  .job-detail > ul li span {
    width: 90%;
  }
  .job-detail h5 {
    font-size: 17px;
  }
  .job-detail > p {
    margin-bottom: 20px;
  }
  .nave-area > li {
    font-size: 12px;
    margin-right: 22px;
  }
  .mega-menu > li > a > span {
    font-size: 11px;
  }
  .mega-menu > li {
    width: 84px;
  }
  .coming-meta > p {
    font-size: 15px;
  }
  .forum-warper > h4 {
    font-size: 14px;
  }
  a.addnewforum {
    padding: 5px 10px;
  }

  .forum-warper > h4 {
    font-size: 14px;
  }
  a.addnewforum {
    padding: 5px 10px;
  }
  .logout-f > h4 {
    font-size: 20px;
  }
  .search-match form button {
    margin-top: -35px;
  }
  .oth-opt {
    margin-top: 10px;
    vertical-align: top;
    width: 53%;
  }
  .age-opt {
    margin-top: 10px;
  }
  .right-detailmeta > ul > li {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    height: initial;
    line-height: initial;
    vertical-align: text-top;
    width: auto;
  }
  .media-info > li {
    font-size: 13px;
    width: 120px;
  }
  .media-info > li i {
    font-size: 16px;
  }
  .media-info {
    margin-bottom: 15px;
    margin-top: 10px;
  }
  .col-1-5 {
    max-width: 100%;
    width: 100%;
  }
  .pitrest-post > a {
    display: inline-block;
    width: 100%;
  }
  .pitrest-title {
    font-size: 18px;
  }
  .add-pitrest {
    margin-bottom: 20px;
    text-align: left;
  }
  .pitrest-pst-hding {
    font-size: 18px;
  }
  .sidebar > .advertisment-box {
    width: 100% !important;
  }
  .pst-change-style .chosen-container {
    width: 67% !important;
  }
  .post-title .number {
    position: unset;
  }
  .sound-right .main-btn2 {
    font-size: 11px;
    padding: 4px 9px;
  }
  .sound-right > a.send-mesg {
    margin: 0 10px;
  }
  .message-writing-box > form > .text-area {
    width: 79.5%;
  }
  .postbox .we-video-info > button {
    padding: 3px 10px;
    font-size: 13px;
  }
  .my-status > .story-user {
    width: 43%;
  }
}

@media screen and (max-width: 360px) {
  .profile-menu > li > a {
    font-size: 12px;
  }
  .invite-figure > h6 {
    width: 77%;
  }
  .blog-post .friend-name {
    width: 87.4%;
  }
  .friend-name {
    width: 84%;
  }
  .birthday-box .frnd-meta {
    width: 79%;
  }
  .birthday-box > figure {
    width: 40px;
  }
  .frnd-name > a {
    font-size: 13px;
  }
  .vdeo-link > h2 {
    font-size: 16px;
  }
  a.main-btn,
  a.main-btn2,
  a.main-btn3 {
    padding: 7px 19px;
  }
  .hourly-forecast > li > i {
    font-size: 24px;
  }
  .hourly-forecast > li {
    width: 24%;
  }
  .nave-area > li {
    margin-right: 17px;
  }
  .align-right.user-ben {
    float: left;
    margin-left: 0;
    text-align: left;
    width: 100%;
  }
  .search-match form button {
    margin-top: 44px;
  }
  .oth-opt {
    width: 66%;
  }

  .rlted-video > figure,
  .rlted-video > figure img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
  .tube-pst-meta {
    max-width: 100%;
    padding-left: 0;
    width: 100%;
    margin-top: 10px;
  }
  .pst-change-style .chosen-container {
    width: 100% !important;
  }
  .copy-email {
    position: unset;
  }
}

@media screen and (max-width: 320px) {
  .error-page {
    padding-top: 0;
  }
  .big-font > span {
    font-size: 60px;
  }
  .we-video-info > ul li {
    margin-right: 24px;
  }
  .we-video-info > ul li span {
    font-size: 14px;
  }
  .we-video-info > ul li span ins {
    font-size: 10px;
  }
  .post-comt-box {
    width: 85%;
  }
  .we-comment {
    width: 83%;
  }
  .friend-name {
    width: 82%;
  }
  .newpst-input {
    width: 80%;
  }
  .newpst-input textarea {
    font-size: 13px;
  }
  .birthday-box .frnd-meta {
    width: 75%;
  }
  .birthday-box .frnd-name {
    width: 99%;
  }
  .post-meta .detail {
    width: 100%;
  }
  .like-dislike {
    left: -15px;
    top: 10px;
    transform: translate(0px, 0px);
  }
  .like-dislike > li {
    margin-bottom: 10px;
  }
  .pit-friends > figure,
  .pit-groups > figure,
  .pit-pages > figure {
    width: 30px;
  }
  .pit-frnz-meta > a,
  .pit-groups-meta > a,
  .pit-pages-meta > a {
    font-size: 12px;
  }
  .add-remove-frnd > li a {
    font-size: 10px;
  }
  .add-remove-frnd > li a > i {
    font-size: 13px;
  }
  .sidebar > .widget,
  .sidebar > .advertisment-box,
  .sidebar > div {
    width: 100%;
  }
  .search-meta > span {
    font-size: 16px;
  }
  .is-helpful > a {
    padding: 9px 10px;
  }
  .friend-block > figure {
    width: 60px;
  }
  .related-groups {
    padding: 0 !important;
  }
  .related-groups .group-box > button {
    font-size: 11px;
    margin-top: 10px;
  }
  .related-groups .group-box > a {
    font-size: 14px;
  }
  .related-groups .group-box > span {
    font-size: 10px;
  }

  .related-groups .group-box > figure img {
    display: inline-block;
    width: 80px;
  }
  .author-info > h4 {
    font-size: 20px;
  }
  .author-info {
    margin-bottom: 20px;
  }
  .weather-tem {
    margin-right: 20px;
  }
  .weather-infos > h3 {
    font-size: 17px;
  }
  .hourly-forecast > li {
    width: 32.3%;
  }
  .list-style > li,
  .colla-apps > li a {
    font-size: 11px;
  }
  .profile-menu > li {
    // margin: 0 10px;
  }

  .user-info > li {
    font-size: 12px;
  }
  .user-info > li span {
    width: 23px;
  }
  .sharing-tools .we-video-info > ul {
    width: 100%;
  }
  .sharing-tools .share-to-other {
    text-align: left;
    width: 100%;
  }
  .jobbox-title {
    width: 100%;
  }
  .friend-name {
    width: 81%;
  }
  .post-meta > h6 {
    max-width: 100%;
  }
  .rate-n-apply .main-btn {
    float: left;
    margin-top: 10px;
  }
  .nave-area > li {
    margin-right: 6px;
  }
  .user-ben > li {
    margin-right: -1px;
  }
  .social-name {
    margin-bottom: 20px;
  }
  .profile-menu > li > a.active::after {
    display: none;
  }
  .total > i {
    font-size: 20px;
    height: 50px;
    line-height: 50px;
    width: 50px;
  }
  .heart-pnts > a {
    font-size: 11px;
  }
  .featured-baner > h2 {
    margin-top: 31px;
  }
  .oth-opt {
    width: 60%;
  }
  .age-opt {
    margin-right: 0;
    width: 48%;
  }
  .uploadtime {
    float: left;
    margin-top: 0;
  }
  .classic-pst-meta > h4 {
    width: 94%;
  }
  .linked-image {
    margin: 0 0 10px;
    width: 100%;
  }
  .central-meta {
    padding: 15px;
  }
  .postbox .friend-name {
    width: 83%;
  }
  .message-writing-box > form > .text-area {
    width: 75.5%;
  }
  .media > ul > li {
    width: 67px;
  }
}

@media screen and (max-width: 1300px) and (min-width: 992px) {
  .top-search button {
    // left: 460px;
  }
  .top-search form input {
    // width: 524px;
  }
  .top-search {
    // margin-left: 40px;
  }
  .setting-area li i {
    // padding: 0.3em 0.4em;
    font-size: 18px;
  }
  .main__home--page .home--page .home--page_column-two .post_icon {
    padding: 12px;
  }
  .main__home--page
    .home--page
    .home--page_column-one
    .section_profile
    .yellow_button {
    width: 58px;
    margin: 4px;
    font-size: 14px;
  }
  .users-thumb-list > a:first-child {
    margin-left: -10px;
  }
  .members-count {
    margin-left: 8px !important;
    font-size: 12px;
  }
  .responsive-archiev {
    width: 12px;
  }
  .responsive-sharedata {
    width: 18px !important;
  }
  .responsive-comment {
    font-size: 18px !important;
  }
  .responsive-likes {
    width: 18px !important;
    height: 18px !important;
  }
  .responsive-comet-avatar {
    max-width: 30px !important;
    width: 30px !important;
  }
  .we-comment-chats {
    padding-left: 7px !important;
  }
  .we-comment-chats a {
    font-size: 12px;
  }
  .we-comment-chats p {
    font-size: 11px;
  }
  .main__home--page
    .home--page
    .home--page_column-one
    .home--page_information
    li
    a
    span {
    font-size: 12px;
  }
  .main__home--page .home--page .home--page_column-one .send__button {
    padding: 6px 16px;
    font-size: 13px;
  }
}

@media screen and (max-width: 991px) {
  .profile-section,
  .cover-pic-container {
    box-shadow: 0px 0px 0px 0px #00000000 !important;
  }
  .profile-menu > li > a.active::after {
    display: none;
  }
  .main__home--page .home--page .home--page_column-one,
  .main__home--page .home--page .home--page_column-three {
    display: none;
  }
  .main__home--page .home--page {
    margin: 0px;
    display: block !important;
  }
  .main__home--page .home--page .home--page_column-two {
    padding: 0;
  }
  .new-postbox {
    padding: 10px;
  }
  .main__home--page .home--page .home--page_column-two .post_icon {
    padding: 12px;
  }
  .attachments > ul .add-loc {
    font-size: 13px;
    margin-top: -1px;
  }
  .attachments li {
    font-size: 12px;
  }
  .post-responsive {
    padding: 0px 24px;
    font-size: 14px;
  }
  .post-responsive span {
    display: none;
  }
  .post-comment-details,
  .post-comment {
    display: flex !important;
  }
  .user-comet-avatar {
    padding: 0;
  }
  .post-details-section {
    padding: 10px !important;
  }
  .post-comt-infm {
    padding-right: 0px;
  }
  .responsive-central-section {
    display: none;
  }
  .lastseen {
    font-size: 11px;
  }
  .post-content {
    font-size: 12px;
  }
  .user-name {
    font-size: 13px;
  }
  .main__home--page .home--page .home--page_column-two .post_images_details {
    width: 60%;
  }
  .post-comt-box {
    padding-left: 14px !important;
  }
  .main__home--page,
  body {
    // background: #a5dad0;
    margin-bottom: 50px;
    padding-top: 0px;
  }
  .main__home--page {
    padding-top: 50px !important;
  }
  .user-image {
    width: 70% !important;
  }
  .responsive-boxshadow {
    box-shadow: none;
  }
  .user-comet-avatar {
    text-align: center;
  }
  .mobileheader {
    display: block !important;
    padding: 20px;
    // margin-top:30px;
    background: #ffcc3a;
  }
  .privacy-slider {
    padding-top: 0px !important;
  }
  .desktop-about {
    padding-top: 0px !important;
  }
  .top-area {
    display: inline-block;
    text-align: right;
    width: 100%;
    background: #ffff;
    border: medium none;
    font-size: 13px;
    padding: 5px 24px;
    color: #fff;
    font-family: sans-serif;
    border-radius: 12px;
  }
  .top-search {
    float: left;
    margin-top: 0px;
    /* text-align: left; */
    width: 100%;
  }
  .top-search form input {
    width: 100%;
  }
  ul.setting-area li.responsiveheader-icon {
    display: inline-block;
    position: relative;
    vertical-align: top;
    z-index: 2;
    border-radius: 60px;
    padding: 0.5em 0.6em;
    background-color: #ffff;
    margin-left: 10px;
  }
  .mobileimg-responsive {
    width: 18px;
  }
  .mobileheader .d-flex,
  .footer-container .d-flex {
    display: flex !important;
  }
  .mobile-searchbar {
    background: #ffff;
    border: medium none;
    font-size: 13px;
    padding: 13px 16px;
    width: 100%;
    font-family: sans-serif;
    border-radius: 26px;
    margin-top: 12px;
  }
  .responsive-search button {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    color: #ffcf34;
    font-size: 15px;
    float: right;
    margin-top: -20px;
    transform: translateY(-50%);
  }
  // Profile Page
  .profile-card {
    padding: 0 !important;
  }
  .profile-pic-container > img {
    max-width: 100px;
    min-width: 100px;
    width: 100px;
    height: 100px;
  }
  .profile-author {
    margin-top: -119px !important;
  }
  // .profile-section {
  //   background: #a5dad0 none repeat scroll 0 0;
  // }
}
