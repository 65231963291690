
.image_view * {
    max-height: 90vh;
    max-width: 90vw;
    object-fit: contain
  }
  .image_view {
  position:fixed;
  height: 100vh;
  width:100vw;
  top:0;
  left:0;
  text-align: center;
  display: flex;
  z-index:999;
  justify-content: center;
  align-items: center;
  background-color:black;
  }
  .view_profile_pic {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  // background-image: url("https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  }
  .user_label {
  white-space: nowrap;
  padding-left: 15px;
  align-self: center;
  }
  .pic_date {
  white-space: nowrap;
  font-size: 13px;
  }
  .comment_sec {
  max-height: 100vh;
  overflow: auto;
  padding: 15px 15px 0px 15px !important;
  }
  
  .v_likes,
  .v_messages,
  .v_sharedata {
  padding-right: 20px;
  display: flex;
  }
  .like_share {
  margin: 30px 0px;
  border: 1px solid #d3d3d3;
  border-left: none;
  border-right: none;
  padding: 10px 4px;
  }
  .like_share span {
  padding-left: 7px;
  align-self: center;
  }
  .comment_list_sec .w-10,
  .user_comments .w-10 {
  width: 50px;
  }
  .comment_list_sec .view_profile_pic,
  .user_comments .view_profile_pic {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-image: url("https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 6px 10px 0px 0px;
  }
  .comment_list_sec textarea {
  border-radius: 12px;
  border: 1px solid #d3d3d3;
  }
  .user_comments {
  margin-bottom: 15px;
  }
  
  .user_comments span {
  align-self: center;
  font-size: 12px;
  padding-left: 4px;
  }
  .user_comments .user_label {
  white-space: inherit;
  padding: 0;
  }
  .comment_content {
  border: 1px solid #d3d3d3;
  border-radius: 12px;
  padding: 5px 12px;
  background: #dedede;
  margin-bottom: 5px;
  }
  .comment_list_sec {
  position: sticky;
  bottom: 0;
  padding: 15px 0px;
  background: #fff;
  }
  @keyframes slide {
  1%,
  18%,
  100% {
    left: 0%;
  }
  20%,
  58% {
    left: -100%;
  }
  60%,
  98% {
    left: -200%;
  }
  }
  
  @keyframes fade {
  1%,
  18%,
  20%,
  58%,
  60%,
  98% {
    opacity: 1;
  }
  19%,
  59% {
    opacity: 0.2;
  }
  99% {
    opacity: 0.5;
  }
  }
  
  .x_close {
  height: 40px;
  width: 40px;
  padding: 10px;
  position: fixed;
  z-index: 1000;
  left: 15px;
  top:15px;
  background: hsl(0, 0%, 0%);
  border-radius: 50%;
  transition: all 0.2s ease;
  cursor: pointer;
  
  &:hover {
    // background: hsl(44, 25%, 10%);
    transform: translate(0px, -5px);
    // color:hsl(44, 100%, 61%)
  }
  }
  .v_logo {
  height: 70px;
  position: fixed;
  z-index: 1000;
  left: 75px;
  top: 15px;
  transition: all 0.2s ease;
  border-radius: 2rem;
  padding: 10px;
  padding-top: 0px;
  &:hover {
    transform: translate(0px, -5px);
    // color:hsl(44, 100%, 61%)
  }
  }
  .prev-post-btn {
  color: white;
  font-size: 3rem;
  position: fixed;
  left: 5%;
  top:50%;
  z-index:1000;
  transform: translate(0, -50%);
  background-color: black;
  border: none;
  }
  .next-post-btn {
  color: white;
  font-size: 3rem;
  position: fixed;
  top: 50%;
  right: 5%;
  z-index:1000;
  transform: translate(0, -50%);
  background-color: black;
  border: none;
  }
  .prev-post-btn:disabled,
  .next-post-btn:disabled {
  color: hsl(0, 0%, 30%) !important;
  cursor: no-drop;
  }
  .image_view * {
    // max-height: 90vh;
    max-width: 100%;
    object-fit: contain;
  }
  